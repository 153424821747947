import React from "react"
import PropTypes from "prop-types"
import Link from "./shared/link"
import { contentExists } from "../utils/ug-utils"

import "../styles/breadcrumbs.scss"

function Breadcrumbs(props) {
  const targetId = String(props.targetId)
  const targetUrl = props.targetUrl
  const activeId = String(props.activeId)
  const activeCrumb = {title: props.activeTitle}
  let crumbs = props.crumbs || []

  const menuData = require("../../config/sitemaps/aad-breadcrumbs.yml")

  const findBreadcrumbTrail = (data, targetId, targetUrl) => {
    const stack = []

    const traverse = (items, path = []) => {
      for (let item of items) {
        const newPath = path.concat(item)
        if (item.drupal_id === targetId || item.url === targetUrl) {
          stack.push(...newPath)
          return true
        }
        if (item.children && item.children.length) {
          if (traverse(item.children, newPath)) {
            return true
          }
        }
      }
      return false
    }

    traverse(data)
    return stack
  }

  if (crumbs.length === 0 && (contentExists(targetId) || contentExists(targetUrl))) {
	  crumbs = findBreadcrumbTrail(menuData, targetId, targetUrl)
  }

  if (crumbs.length === 0) {
    crumbs = [activeCrumb]
  } else if (!crumbs.some(crumb => crumb.drupal_id === activeId || crumb.title === activeCrumb.title)) {
    crumbs.push(activeCrumb)
  }

  return (
    <nav aria-label="Breadcrumbs">
        <ol className="breadcrumb__list">
          <li className="breadcrumb__list__item">
            <a className="breadcrumb__link" href="/" aria-label="Home">
              <i className="fa-solid fa-house">
                <span className="sr-only">Home</span>
              </i>
            </a>
          </li>
          {crumbs.map((crumb, index) => (
            <li key={crumb.id} className="breadcrumb__list__item">
              {index < crumbs.length - 1 ? (
                <Link className="breadcrumb__link" to={crumb.url} aria-label={crumb.title}>{crumb.title}</Link>
              ) : (
                <span aria-current="page">{crumb.title}</span>
              )}
            </li>
          ))}
        </ol>
    </nav>
  )
}

Breadcrumbs.propTypes = {
  targetId: PropTypes.number,
  targetUrl: PropTypes.string,
  activeId: PropTypes.number,
  activeTitle: PropTypes.string,
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
}

Breadcrumbs.defaultProps = {
  targetId: null,
  nodeTitle: ``,
  crumbs: [],
}

export default Breadcrumbs
