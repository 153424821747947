import React from "react"
import AadSavings from "./cardLayouts/aadSavings"
import { getPathByNodeId } from "../utils/ug-utils"

const SavingsTeaser = ({ node, className }) => {
  const url = node?.path?.alias || "#"
  //const cta = node.field_call_to_action_link
  const cta = { title: "View Offer Details", uri: url }
  var imageData
  imageData = node.relationships.field_image.localFile.childImageSharp._card_image
  className = "savings-teaser-card"

  const image = {
    gatsbyImageData: imageData,
    alt: node.field_image.alt,
  }

  return (
    <AadSavings
      cardStyle="aad-savings"
      url={url}
      title={node.title}
      text={node.title}
      image={image}
      link_class="savings-link"
      className={className}
      cta={cta}
      ariaLabel={node.title}
    />
  )
}

export default SavingsTeaser
