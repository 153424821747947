import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col, Button } from "react-bootstrap"
import { graphql } from "gatsby"
import "../components/fragments"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import MasonryResponsive from "../components/masonryResponsive"
import Select from 'react-select'
import SavingsTeaser from "../components/savingsTeaser"
import "../styles/savings.scss"
import GridParent from "../components/gridParent"
import Grid from "../components/grid"
import GridCell from "../components/gridCell"
import HeroSpotlight from "../components/heroSpotlight"
import Breadcrumbs from "../components/breadcrumbs"

const SavingsPage = ({ data, pageContext }) => {
  // const { name } = pageContext
  const savingsData = data.savings.edges
  const savingsLocations = data.savingsLocations.edges.map((edge) => { return {value: edge.node.id, label: edge.node.name} })
  const savingsTypes = data.savingsTypes.edges.map((edge) => { return {value: edge.node.id, label: edge.node.name} })
  const heroImage = <StaticImage src="../images/alumni-savings.jpg" alt="U of G Alumni Savings" />
  const breakpointColumnsObj = {
    default: 4,
    1200: 3,
    992: 2,
    576: 1
  };
  const customSelectStyles = {
    dropdownIndicator: base => ({
      ...base,
      color: "#c20430"
    })
  };
  const customSelectTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary75: "#c7395a",
      primary50: "#ca788b",
      primary25: "#fde6ec",
      primary: "#c20430"
    }
  })

  const [filters, setFilter] = useState({
    locations: "",
    types: "",
  })
  const [list, setList] = useState(savingsData.filter((saving) => 
    filters.locations !== "" ? saving.node.relationships.field_locations.some((location) => (location.id === filters.locations)) : true &&
    filters.types !== "" ? saving.node.relationships.field_type.some((type) => (type.id === filters.types)) : true
  ))

  useEffect(() => {
    setList(savingsData.filter((saving) => 
      filters.locations !== "" ? saving.node.relationships.field_locations.some((location) => (location.id === filters.locations)) : true &&
      filters.types !== "" ? saving.node.relationships.field_type.some((type) => (type.id === filters.types)) : true
    ))
  }, [filters])

  const handleOnChangeLocations = (e) => {
    let value = e === null ? "" : e.value;
    setFilter(previousState => {
      return { ...previousState, locations: value }
    });
  }

  const handleOnChangeTypes = (e) => {
    let value = e === null ? "" : e.value;
    setFilter(previousState => {
      return { ...previousState, types: value }
    });
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "saving-list-page",
          "aria-label": "Special Offers List"
        }}
      />
      <Seo title={`Special Offers`} keywords={[`gatsby`, `application`, `react`]} />

      {/**** Header and Title ****/}
      <div id="rotator" className="short-hero image">
        <Hero staticImage={heroImage} />
        {/* <div className="container ft-container">
        </div> */}
        <div>
          <HeroSpotlight>
            <h1 className="fancy-title" aria-label={"Special Offers"}>Special Offers</h1>
          </HeroSpotlight>
        </div>
      </div>

      {/**** Body content ****/}
      <div className="page-container">
        <Container>
          <Breadcrumbs targetUrl="/special-offers" activeTitle="Special Offers" />
          <Row className="row-with-vspace site-content">
            <Col sm={6} lg={3}>
              <Select 
                options={savingsTypes} 
                onChange={handleOnChangeTypes} 
                placeholder="Filter by type"
                isClearable={true} 
                styles={customSelectStyles}
                theme={customSelectTheme}
                aria-label="Filter by type"
              />
            </Col>
            {/* <Col sm={6} lg={3}>
              <Select
                options={savingsLocations}
                onChange={handleOnChangeLocations}
                placeholder="Filter by location"
                isClearable={true}
                styles={customSelectStyles}
                theme={customSelectTheme}
                aria-label="Filter by location"
              />
            </Col> */}
          </Row>
          <Row className="row-with-vspace site-content card-collection-wrapper">
            <Col md={12} className="content-area">
              {/**** Body content ****/}
              <Container>
                {list.length > 0 ? (
                  <Grid breakpointCols={breakpointColumnsObj}>
                    {list.map(({ node }, index) => {
                      return (
                        <GridCell key={node.id}>
                          <SavingsTeaser node={node} className="masonry-card"/>
                        </GridCell>
                      )
                    })}
                  </Grid>
                ) : (
                  <p>No savings to display.</p>
                )}
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default SavingsPage

export const query = graphql`
  {
    savings: allNodeAlumniSavings(limit: 1000, sort: { fields: created, order: DESC }) {
      edges {
        node {
          ...Savings
        }
      }
    }
    savingsLocations: allTaxonomyTermLocations(
      # Ensure there are nodes
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          ...TaxonomyTerm
        }
      }
    }
    savingsTypes: allTaxonomyTermSavingsType(
      # Ensure there are nodes
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          ...TaxonomyTerm
        }
      }
    }

  }
`
