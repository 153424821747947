import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Masonry from "react-masonry-css"
import { Button } from "react-bootstrap"

export const MasonryResponsive = ({ children, useLoadMore, itemsPerPage, ...props }) => {
  const [list, setList] = useState([children.slice(0, itemsPerPage)])
  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(children.length > itemsPerPage)

  const handleLoadMore = () => {
    setLoadMore(true)
  }

  useEffect(() => {
    setList([children.slice(0, itemsPerPage)])
  }, [children])

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < children.length
      const nextResults = isMore ? children.slice(currentLength, currentLength + itemsPerPage) : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  useEffect(() => {
    const isMore = list.length < children.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  return useLoadMore ? (
    <>
      <Masonry {...props}>{list}</Masonry>
      {children.length > itemsPerPage ? (
        <div className="load-more-container">
          {hasMore ? <Button variant="outline-primary" onClick={handleLoadMore}>Load More</Button> : <p>No more results</p>}
        </div>
      ) : null}
    </>
  ) : (
    <Masonry {...props}>{children}</Masonry>
  )
}

MasonryResponsive.propTypes = {
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
  breakpointCols: PropTypes.number |  PropTypes.object,
  className: PropTypes.string,
  columnClassName: PropTypes.string,
  useLoadMore: PropTypes.bool,
  itemsPerPage: PropTypes.number,
  numColumns: PropTypes.number,
}

MasonryResponsive.defaultProps = {
  breakpointCols: {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  },
  className: "ug-masonry-grid",
  columnClassName: "ug-masonry-grid_column",
  useLoadMore: false,
  itemsPerPage: 12,
}

export default MasonryResponsive
