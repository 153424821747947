import React from "react"
import "../styles/heroSpotlight.scss"

function HeroSpotlight(props) {
  const heroStyle = props.heroStyle || "short-hero"
  const width = props.width ?? 50

  return (
    <div className="spotlight-hero-content h-100 w-100 p-0" aria-label="Hero Spotlight">
      <div
        className={`${heroStyle} spotlight-hero-caption-container bottom-0 start-0 text-white`}
        style={{ "--spotlight-width": `${width}%` }}
      >
        <div className="spotlight-hero-caption d-flex flex-column gap-4 p-0">
          {props.children}
          {/* {React.Children.map(props.children, child => {
            if (React.isValidElement(child) && child.type === 'img' && !child.props.alt) {
              return React.cloneElement(child, { alt: "Description of the image" });
            }
            return child;
          })} */}
        </div>
      </div>
    </div>
  )
}

export default HeroSpotlight
