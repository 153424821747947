import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { contentExists, renderFormattedText } from '../../utils/ug-utils'
import Bulletlink from "../bulletLink"
import Link from "../shared/link"

const AadSavings = ({ image, text, url, link_class, cta, title }) => {
  const renderedText = renderFormattedText(text, true, { length: 120 })
  const gatsbyImage = image?.gatsbyImageData && <GatsbyImage alt={image.alt || "Description of the image"} image={image.gatsbyImageData} />
  return (
    <div className={link_class}>
      {/* Add aria-label to Link for better accessibility */}
      <Link
        to={url}
        className="card_link"
        aria-label={`Learn more about ${title}${renderedText ? `: ${renderedText}` : ''}`}
      >
        <div className="card_image-container">
          {gatsbyImage}
        </div>
      </Link>
      <div className="card_bottom">
        {contentExists(renderedText) && <div className="card_text">{renderedText}</div>}
        { cta &&
          // Add aria-label to Bulletlink for better accessibility
          <Bulletlink uri={cta.uri} aria-label={`Go to ${cta.title}`}>{cta.title}</Bulletlink>
        }
      </div>
    </div>
  )
}

AadSavings.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.element,
  text: PropTypes.any,
  url: PropTypes.string,
}

export default AadSavings