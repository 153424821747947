import PropTypes from "prop-types"
import React from "react"
import Link from "./shared/link"

function Bulletlink({children, uri}) {
  return (
    <div className="bullet-link">
      <i className="fa-solid fa-play" aria-hidden="true"></i>
      <Link className="link-item" to={uri}>{children}</Link>
    </div>
  );
}

Bulletlink.propTypes = {
  uri: PropTypes.string,
}

Bulletlink.defaultProps = {
  uri: "#",
}

export default Bulletlink